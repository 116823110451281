import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Link from 'gatsby-link'

import styles from "./poemFooter.module.scss"

function PoemFooter({
  className, next, prev, variant
}) {
  return (
    <footer
      className={ classNames(styles.poemFooter, className) }
    >
      <div className={ classNames(styles.meta, styles[variant]) }>
        <p>by<br/>sarah quigley</p>
      </div>
      <nav className={ variant === 'light' ? styles.dark : styles.light }>
        { prev &&
          <Link
            className={ classNames(styles.prev, styles.navButton, variant === 'light' ? styles.dark : styles.light) }
            to={ prev.path }
          ><span>{ '<-' }</span></Link>
        }
        { !prev &&
          <div
            className={ classNames(styles.prev, styles.navButton, variant === 'light' ? styles.dark : styles.light) }
          />
        }
        {
          next &&
          <Link
            className={ classNames(styles.next, styles.navButton,  variant === 'light' ? styles.dark : styles.light) }
            to={ next.path }
          ><span>{ '->' }</span></Link>
        }
        {
          !next &&
          <div
            className={ classNames(styles.next, styles.navButton,  variant === 'light' ? styles.dark : styles.light) }
          />
        }
      </nav>
    </footer>
  )
}

PoemFooter.propTypes = {
  className: PropTypes.string,
  next: PropTypes.shape({
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  prev: PropTypes.shape({
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  variant: PropTypes.oneOf(['light', 'dark'])
}

PoemFooter.defaultProps = {
  className: null,
  next: null,
  prev: null,
  variant: null
}

export default PoemFooter
