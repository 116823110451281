import React from "react"
import classNames from 'classnames'
import { graphql } from "gatsby"

import Layout from '../../components/layout/layout'
import Nav from '../../components/nav/nav'
import PoemContent from '../../components/poemContent/poemContent'
import PoemFooter from '../../components/poemFooter/poemFooter'
import PoemHeader from '../../components/poemHeader/poemHeader'

import styles from "./poem.module.scss"

function Poem({
  data, // this prop will be injected by the GraphQL query below.
  pageContext
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { fields, frontmatter, html } = markdownRemark
  const { next, prev, variant } = pageContext
  const oppositeVariant = variant === 'dark' ? 'light' : 'dark'

  return (
    <Layout
      className={ classNames(styles.poem, styles[variant]) }
      pageMetadata={ {
        description: frontmatter.excerpt,
        image: fields.socialCardImage,
        slug: fields.slug,
        title: frontmatter.title || '{ Untitled }',
      } }
    >
      <div
        className={ classNames(styles.container, styles.navContainer, styles[oppositeVariant]) }
      >
        <Nav
          className={ classNames(styles.nav) }
          format="single"
          variant={ oppositeVariant }
        />
      </div>
      <article>
        <div
          className={ styles.poemBody }
        >
          <div
          className={ classNames(styles.container, styles.poemHeaderContainer, styles[variant]) }
          >
            <PoemHeader
              className={ styles.poemHeader }
              date={ frontmatter.date }
              tag="header"
              title={ frontmatter.title || '{ Untitled }'}
              format="single"
              titleFontSize={ frontmatter.titleFontSize }
              variant={ variant }
            />
          </div>
          <div
            className={ classNames(styles.container, styles.poemContentContainer, styles[oppositeVariant]) }
          >
            <PoemContent
              className={ styles.poemContent }
              fontSize={ frontmatter.contentFontSize }
              html={ html }
              variant={ oppositeVariant }
            />
          </div>
        </div>
        <PoemFooter
          className={ styles.poemFooter }
          next={ next }
          prev={ prev }
          variant={ variant }
        />
      </article>
    </Layout>
  )
}

export default Poem

export const query = graphql`
  query($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      fields {
        slug
        socialCardImage
      }
      frontmatter {
        contentFontSize
        date(formatString: "YYYY · MM · DD")
        excerpt
        title
        titleFontSize
      }
    }
  }
`
