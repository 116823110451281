import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from "./poemContent.module.scss"

function PoemContent({ className, fontSize, html, variant }) {
  fontSize = fontSize ? `${fontSize}em` : null;

  return (
    <div
      className={ classNames(styles.poemContent, className, styles[variant]) }
    >
      <div
        className={ styles.content }
        dangerouslySetInnerHTML={{ __html: html }}
        style={ { fontSize } }
      />
    </div>
  )
}

PoemContent.propTypes = {
  className: PropTypes.string,
  fontSize: PropTypes.number,
  html: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['light', 'dark'])
}

PoemContent.defaultProps = {
  className: null,
  fontSize: null,
  variant: null
}

export default PoemContent
